<template>
  <div class="user-login login-scale user-login-mobile">
    <img src="/static/mebile-login-bg.png" class="user-login-mobile-bg" />
    <div class="logBox">
      <div></div>
      <img class="logo" src="@/assets/img/logLion.png" alt />
    </div>
    <ul class="test">
      <li>环境：{{ envStr }}--{{ webViewEnvStr }}</li>
      <li>
        <el-button size="small" class="user-login-btn btn-green" type="primary" @click="goHome">
          跳转首页
        </el-button>
      </li>
      <li>
        <el-button size="small" class="user-login-btn btn-green" type="primary" @click="onRefresh">
          刷新当前页
        </el-button>
      </li>
      <li>
        <el-button size="small" class="user-login-btn btn-green" type="primary" @click="onMessage">
          发送测试消息{{ msnumber }}
        </el-button>
      </li>
    </ul>
    <div class="login-box">
      <dl class="login-box-card">
        <dt class="login-box-header">
          <h2 class="login-box-header-welcome">WELCOME</h2>
          <div class="tab-box">
            <div v-if="isShowLionBtn" class="login-box-row lionid">
              <el-button
                size="small"
                class="user-login-btn btn-green"
                type="primary"
                @click="goLionid"
              >
                LOGIN WITH LION ID
              </el-button>
            </div>
          </div>
        </dt>
      </dl>
    </div>

    <copyright-footer :isMobile="true" />
  </div>
</template>

<script>
import uni from '@/assets/js/uni.webview.1.5.6.js'
import CopyrightFooter from '@/components/CopyrightFooter'

export default {
  name: 'Weixin',
  components: {
    CopyrightFooter,
  },
  data() {
    return {
      envStr: '',
      webViewEnvStr: '',
      msnumber: 0,
      pageStatus: '初始化中...',
      lastMessage: '无',
      messageHandler: null,
      uniObj: null,
    }
  },
  computed: {
    token() {
      return this.$sessionStorage.getItem('Access-Token')
    },
    lionId() {
      return this.$route.query.lionId
    },
    // 微信 code 码
    wxCode() {
      return this.$route.query.code
    },
    // 是否显示 Lionid 登录按钮
    isShowLionBtn() {
      // return !(this.token && this.wxCode)
      return !(this.lionId && this.wxCode)
    },
  },
  methods: {
    goHome() {
      // 刷新当前页面，确保页面能获取到通信消息，这是一个特殊处理，只有当前页刷新，小程序才能获取到uni.postMessage的消息
      uni.redirectTo({
        url: '/pages/index/index',
      })
    },
    onRefresh() {
      uni.redirectTo({
        url: '/pages/login/login',
      })
    },
    onMessage() {
      // 发送消息给微信小程序
      uni.postMessage({
        target: 'test',
        data: {
          type: 'test',
          number: this.msnumber,
          timestamp: Date.now(),
        },
      })
      this.msnumber++
    },
    /**
     * 完成微信端的 SSO Lionid 登录
     */
    goLionid() {
      window.location.href = $Api.user.goLionidWeixin({
        xwCode: this.wxCode,
        callbackUrl: window.encodeURIComponent(window.location.href),
        //callbackUrl:  window.encodeURIComponent(`${window.location.origin}${this.$route.fullPath}`),
        appName: 'wechat_app',
      })
    },
    // test
    handleClick() {
      this.uniObj = uni.postMessage
      console.log('点击了发送按钮', uni.postMessage)
      uni.postMessage({
        target: 'weixin',
        data: {
          token: this.token,
          type: 'click post',
          timestamp: Date.now(),
        },
      })
    },
    // test
    handleRefresh() {
      console.log('点击了刷新')
      uni.redirectTo({
        url: '/pages/login/login',
      })
    },
    // test
    handleToUser() {
      console.log('点击了跳转 User')
      uni.switchTab({
        url: '/pages/user/user',
      })
    },
  },
  mounted() {
    console.log('页面元素初始化...')
  },
  created() {
    console.log('页面加载...')
    // 监听 UniAppJSBridgeReady 事件，以确保能跟微信小程序进行通信
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.uniObj = uni

      uni.getEnv((res) => {
        console.log('微信小程序专属登录页，当前环境：' + JSON.stringify(res))
        this.envStr = res
      })

      uni.webView.getEnv((res) => {
        console.log('webView 环境：' + JSON.stringify(res))
        this.webViewEnvStr = res
      })

      uni.postMessage({
        // target: 'mp-weixin',
        data: {
          type: 'test11',
          timestamp: Date.now(),
        },
      })

      uni.webView.postMessage({
        // target: 'mp-weixin',
        data: {
          type: 'test22',
          timestamp: Date.now(),
        },
      })

      // 当完成登录时，发送消息给微信小程序
      if (!this.isShowLionBtn) {
        // 发送消息给微信小程序
        uni.postMessage({
          target: 'mp-weixin',
          data: {
            // token: this.token,
            token: this.lionId,
            type: 'login-success',
            timestamp: Date.now(),
          },
        })
        // 刷新当前页面，确保页面能获取到通信消息，这是一个特殊处理，只有当前页刷新，小程序才能获取到uni.postMessage的消息
        uni.redirectTo({
          url: '/pages/loading/loading',
        })
      }
    })
  },
  beforeDestroy() {
    console.log('页面即将销毁...')
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/css/var.scss';
$text-color: #fff;

.user-login-mobile {
  // background: #000!important;
  // background-image: url('/static/mebile-login-bg.png');
  // background-position: center center;
  // background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;

  .user-login-mobile-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    // transform: translate(0, -50%);
  }

  .login-box {
    top: 62% !important;
  }

  .login-box-header-welcome {
    font-size: 56px;
    color: #fff;
    margin: 26px 0;
    font-weight: 500;
  }

  .login-box-body {
    width: 100% !important;

    .login-box-row {
      // padding: 0 2px;
    }
  }

  .record {
    bottom: 3%;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
    display: flex;
    align-items: center;

    img {
      margin: 0 4px 0 6px;
    }

    a {
      display: flex;
      align-items: center;
    }

    a,
    a:link,
    a:visited,
    a:hover,
    a:active,
    a:focus {
      color: #fff;
    }
  }

  .test {
    position: absolute;
    top: 20%;
    left: 10%;
    margin: 0 auto;
    width: 80%;
    color: #fff;
    background: transparent;
    li {
      margin-bottom: 10px;
    }
  }
}
</style>
